.staff-management-container {
  padding: 16px;
  width: 100%;
  margin-bottom: 600px;
  margin-left: 180px;
}

.staff-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 16px;
  margin-top: 100px;
  margin-left: 225px;
}

.staff-table {
  width: 1400px;
  border-collapse: collapse;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  margin-top: 3rem;
  margin-left: 220px;
}

.staff-table th,
.staff-table td {
  padding: 12px 30px;
  text-align: center; /* Center align for both th and td */
  vertical-align: middle; /* Vertically center the content */
}

.staff-table th {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.01em;
  color: #B5B7C0;
}

.staff-table td {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.01em;
  color: #292D32;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.button-container {
  text-align: center; /* Center align for button container */
}

#attendance-button {
  margin-top: 10px;
  background-color: #007bff; 
  color: white;
  font-size: 12px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: inline-block;
  width: 100%;
  padding: 0.4rem;
}

#attendance-button:hover {
  background-color: #5570F1;
}

/* Responsive styles */
@media (max-width: 768px) {
  .staff-table th,
  .staff-table td {
    padding: 8px;
  }

 

  #attendance-button {
    padding: 2px 5px;
    font-size: 11px;
  }
}

.toaster_icon {
  background-color: #4caf50;
  color: #ffffff;
  padding: 12px 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease;
  position: relative;
  max-width: 300px;
  margin: 10px auto;
  font-size: 16px;
}

.toaster_icon:hover {
  transform: translateY(-2px);
}

.toaster_icon::before {
  content: '✔';
  margin-right: 10px;
  font-size: 20px;
}

.toaster_icon-close {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #ffffff;
  font-size: 18px;
}

/* Additional styles for the update class and table responsiveness */
.update {
  display: flex;
  width: 100%;
}

.table_responsive {
  overflow-x: auto;
}
/* Responsive adjustments for tablet view (1024px and below) */
@media (max-width: 1400px) and (min-width: 900px) {
  .staff-management-container {
      width: 100%;
      margin-left: 300px;
      padding: 10px;
  }

  .staff-title {
      margin-left: 50px;
  }

  .staff-table {
      width: 1200px;
      margin-left: 50px;
  }

  #attendance-button {
      font-size: 11px;
      padding: 8px 12px;
  }
  .update {
    display: flex;
  }
}
@media (min-width: 1150px)
{
  .update{
    display: flex;
    width: auto;
  }
}

/* Adjustments for screens less than 920px */
@media (max-width: 1150px) {
  .staff-management-container {
      margin: 0 auto;
      padding: 8px;
      margin-left:300px;
      margin-bottom: 400px;
  }

  .staff-title {
      margin-left: 20px;
  }

  .staff-table {
      width: 100%;
      margin-left: 20px;
      font-size: 13px;
  }

  .staff-table th,
  .staff-table td {
      padding: 8px;
  }

  #attendance-button {
      padding: 6px 10px;
      font-size: 10px;
  }
}

/* Mobile view adjustments (768px and below) */
@media (max-width: 768px) {

 
  .staff-management-container {
      margin: 0 auto;
      padding: 5px;
  }

  .staff-title {
      margin-left: 10px;
  }

  .staff-table {
      width: 100%;
      font-size: 12px;
      margin-left: 0;
  }

  .staff-table th,
  .staff-table td {
      padding: 6px;
  }

  #attendance-button {
      font-size: 10px;
      padding: 4px 8px;
  }
}
