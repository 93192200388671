/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', sans-serif;
}

html, body {
  height: 100%;
}

/* Invoice Content Styles */
.invoice_content {
  display: flex;
  justify-content: flex-end; /* Align to the right */
  padding: 10px;
  width: 100%;
}

/* Invoice Sections */
#invoice_left, #invoice_right {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 400px; /* Adjusted width */
  background-color: white;
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-left: 20px; /* Space between sections */
}

/* Invoice Header Styles */
.invoice_header {
  border: 1px solid black;
  padding: 10px;
  display: flex;
  justify-content: center; /* Space between logo and name */
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

/* Logo Styles */
.invoice_logo {
  margin-left: 10px; /* Reduced margin */
  width: 15%; /* Logo width */
  height: auto;
}

/* Invoice Name Styles */
.invoice_name {
  font-size: 1.5rem; /* Invoice title size */
  font-weight: bold;
  text-align: center;
}

/* Footer Styles */
#invoice_footer {
  display: flex;
  justify-content: space-between; /* Align to the right */
  padding: 10px;
}

/* Footer Left and Right Styles */
#invoice_footer_left, #invoice_footer_right {
  display: flex;
  flex-direction: column;
}



/* Select Field Styles */
.appoint_select-field-cont {
  display: flex;
  flex-direction: column; /* Stack vertically */
  width: 400px;
  margin-left: 120px; /* Align left section */
}

.appoint_select-field-cont select {
  width: 100%; /* Full width */
  padding: 10px;
  border-radius: 8px; /* Rounded corners */
  margin: 5px 0 10px; /* Vertical margin */
  transition: border-color 0.3s ease; /* Transition for focus effect */
  font-size: 1rem; /* Consistent font size */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  #invoice_left, #invoice_right {
    width: 100%; /* Full width on smaller screens */
  }

  .invoice_content {
    flex-direction: column; /* Stack sections */
    align-items: flex-start; /* Align to the left */
  }

  #invoice_footer {
    align-items: flex-start; /* Align footer to the left */
  }

}
.generate-button{
  height: 60px;
  width: auto;
}