/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal Content */
.modal-content {
    background: #ffffff;
    width: 90%;
    max-width: 900px; /* Larger width for bigger screens */
    max-height: 90%; /* Ensure modal doesn't exceed the viewport */
    border-radius: 12px;
    padding: 20px 30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease-in-out;
    position: relative;
    overflow-y: auto; /* Scrollable content */
}

/* Smooth scrolling */
.modal-content::-webkit-scrollbar {
    width: 8px;
}

.modal-content::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 8px;
}

.modal-content::-webkit-scrollbar-thumb:hover {
    background-color: #999999;
}

/* Modal Title */
#modal-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-align: left;
}

/* Form Section */
.forms-sections h3 {
    font-size: 1rem;
    font-weight: 600;
    color: #555;
    margin-bottom: 10px;
}

/* Form Row */
.forms-rows {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    margin-bottom: 20px;
}
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#close-btn {
    font-size: 2.5rem;
    background: none;
    border: none;
    cursor: pointer;
    color: red !important;
}

/* Styles specific to inventory-related fields */
#inventory-field {
    width: 100%;
    padding: 10px 15px;
    font-size: 0.9rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    background: #f9f9f9;
    outline: none;
    transition: border-color 0.3s;
    color: #9C9D9E;
    margin-top: 1rem;
    font-weight: bold;
}

#inventory-field:focus {
    border-color: #4a90e2;
    background: #ffffff;
}
#expense-field,
#inventory-field,
textarea {
    width: 100%; /* Ensure all inputs take up the full width of their container */
    padding: 10px 15px;
    font-size: 0.9rem;
    height: 40px; /* Set a consistent height */
    border: 1px solid #ccc;
    border-radius: 8px;
    background: #f9f9f9;
    outline: none;
    transition: border-color 0.3s;
    box-sizing: border-box; /* Include padding and border in the total width and height */
}
#expense-field:focus,
#inventory-field:focus,
textarea:focus {
    border-color: #4a90e2;
    background: #ffffff;
}



/* Ensure fields in the inventory container align neatly */
#inventory-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three fields per row */
    gap: 15px;
    margin-bottom: 20px;
}

/* Responsive adjustments for inventory container */
@media (max-width: 768px) {
    #inventory-container {
        grid-template-columns: 1fr; /* Stack fields in a single column for smaller screens */
    }
}

#expense-field {
    width: 100%;
    padding: 10px 15px;
    font-size: 0.9rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    background: #f9f9f9;
    outline: none;
    transition: border-color 0.3s;
    color: #9C9D9E;
    margin-top: 1rem;
    font-weight: bold;

}

#expense-field:focus {
    border-color: #4a90e2;
    background: #ffffff;
}

/* Add Inventory Button */
#add-inventory-btn {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 0.9rem;
    color: #ffffff;
    background-color: #4a90e2;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
}

#add-inventory-btn:hover {
    background-color: #357abd;
}
#buttons-add
{
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Notes Section */
textarea {
    resize: none;
    width: 30%;
    font-weight: 600;
}

/* Create Expense Button */
#create-expense-btn {
    padding: 12px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
    background-color: #4a90e2;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s;
    margin-left: 1rem;
}

#create-expense-btn:hover {
    background-color: #357abd;
}

/* Responsive Design */
@media (min-width: 1024px) {
    .modal-content {
        max-width: 1000px; /* Larger modal on bigger screens */
        max-height: 80%; /* Less height to show more of the modal */
        padding: 30px 40px;
    }

    .modal-title {
        font-size: 2rem; /* Larger title on bigger screens */
    }
}

@media (max-width: 768px) {
    .modal-content {
        padding: 15px 20px;
    }

    .forms-rows {
        grid-template-columns: 1fr;
    }

    .modal-title {
        font-size: 1.5rem;
    }
}
