* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Montserrat', sans-serif;
  }
  
  .fg_container {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .fg_main {
    height: 30vh;
    background-color: #091A44;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .fg_logo_img {
    width: auto;
    height: 80px; 
  }
  
  .fg_header {
    color: white;
    z-index: 2;
    margin-top: 10px;
    font-family: 'Montserrat', sans-serif;
  }
  
  .fg_bottom {
    height: 70vh;
    background-color: #f1f1f1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex: 1;
  }
  
  .fg_content {
    width: 80%;
    max-width: 500px;
    background-color: #ffffff; /* White background */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Shadow effect */
    padding: 20px; /* Add padding as needed */
    z-index: 2; /* Place it above fg_main and fg_bottom */
  }
  
  .fg_input-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    flex-direction: column;
  }
  
  .fg_input-container label {
    margin-bottom: 10px;
    font-weight: bold;
    width: 100%;
    text-align: left;
  }
  
  .fg_input-container input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .fg_button-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  
  .fg_submit-button {
    padding: 10px 20px;
    background-color: #091A44;
    color: white;
    width: 100%;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'Montserrat', sans-serif;
  }
  
  .fg_submit-button:hover {
    background-color: #0e274c;
  }
  
  /* For Tablets */
  @media only screen and (max-width: 768px) {
    .fg_header {
      font-size: 28px;
    }
  
    .fg_content {
      width: 90%;
    }
  }
  
  /* For Phones */
  @media only screen and (max-width: 480px) {
    .fg_main {
      height: auto;
    }
  
    .fg_logo_img {
      height: 60px;
    }
  
    .fg_header {
      font-size: 26px;
    }
  
    .fg_content {
      width: 100%;
      padding: 15px;
    }
  
    .fg_submit-button {
      padding: 10px;
    }
    
    .fg_main{
        height: 40vh;
    }
  }
  