* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Montserrat', sans-serif;
}

.admin_inventory_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-top: 100px;
    margin-left: 280px;
    margin-bottom: 200px;
}

#inventory_details_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1500px; /* Increased max width */
}

#inventory_details_header h1 {
    white-space: nowrap;
    margin-right: 20px;
    font: bold;
    margin-left: 1rem;
}

#add_inventory_button {
    background-color: transparent;
    color: white;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    width: 160px;
    font-family: 'Montserrat', sans-serif;
    background-color:#5570F1;
    margin-left: 40px;
}

#add_inventory_button:hover {
    background-color: #4560d1;
    color: white;
}

.horizontal_line_container {
    display: flex;
    justify-content: center;
}

.horizontal_line {
    border: none;
    height: 1px;
    width: 1400px;
    background-color: #091a44;
    margin: 10px 0;
    margin-right: 150px;
    margin-left: 150px;
}

.admin_inventory_table_container {
    width: 1500px; /* Increased width for table container */
    margin-top: 20px;
}

.admin_inventory_table {
    width: 100%; /* Table will take the full width of its container */
    border-collapse: separate; /* Prevent borders from collapsing */
    margin-top: 20px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    margin-right: auto; /* Center the table */
    border: 1px solid #e0e0e0; /* Light border for the table */
}

.admin_inventory_table th,
.admin_inventory_table td {
    padding: 12px 8px;
    text-align: center; /* Maintain center alignment */
    border: none; /* Remove lines between cells */
}

.admin_inventory_table th {
    background-color: #ffffff; /* Updated color */
    color: #B5B7C0; /* Font color for headings */
    font-family: 'Poppins', sans-serif; /* Updated font family */
    font-size: 14px; /* Updated font size */
    font-weight: 500; /* Updated font weight */
    line-height: 21px; /* Updated line height */
    letter-spacing: -0.01em; /* Updated letter spacing */
}

.admin_inventory_table td {
    background: #ffffff; /* Updated background color for data cells */
    color: #292D32; /* Font color for data */
    font-family: 'Poppins', sans-serif; /* Updated font family */
    font-size: 14px; /* Updated font size */
    font-weight: 500; /* Updated font weight */
    line-height: 21px; /* Updated line height */
    letter-spacing: -0.01em; /* Updated letter spacing */
}


/* Responsive adjustments for mobile view 624px
 */
@media only screen and (max-width: 760px) {
    .admin_inventory_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        margin-top: 100px;
        margin-left: 0;
        margin-bottom: 200px;
    }
}

/* more that 1024 px */

@media only screen and (min-width: 1025px) {
    
}


