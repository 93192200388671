.popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    backdrop-filter: blur(5px); /* Blurred background */
    width: 600px;
    padding: 20px;
    border-radius: 5px;
    z-index: 9999;
    animation: popupFadeIn 0.5s ease; /* Animation for popup appearance */
  }
  
  body {
    font-family: 'Montserrat', sans-serif;
  }
  
  .popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
  }
  
  .close-button {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
  }
  
  @keyframes popupFadeIn {
    from {
      opacity: 0;
      transform: translate(-50%, -60%);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
  