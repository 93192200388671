* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Admin_login_container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
}

.admin_login_main {
  display: flex;
  width: 90%;
  max-width: 1200px;
}

.admin_left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.admin_logo {
  text-align: center;
  margin-bottom: 20px;
}

.admin_S_logo {
  width: 150px;
}

.admin_form {
  max-width: 400px;
  margin: 0 auto;
}

.admin_login_head {
  font-size: 30px;
  color: #091A44;
  margin-bottom: 20px;
  text-align: center;
}

.AL_input-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}
#phone-number{
  width: 94%;
  height: 45px;
  padding: 10px 35px; /* Added padding for the lock icon */
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-top: 5px;
} 
#password{
  width: 100%;
  height: 45px;
  padding: 10px 40px; /* Added padding for the lock icon */
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-top: 5px;

}


.password-input-container {
  position: relative;
}

.lock-icon {
  position: absolute;
  left: 10px; /* Position of the icon */
  top: 50%;
  transform: translateY(-50%);
  color: #888;
}

#btn {
  width: 200px;
  height: 45px;
  background-color: #5570F1;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  margin-left:90px;
} 

.forgot-password {
  margin-top: 10px;
  text-align: center;
}

.forgot-password a {
  color: #007bff;
  text-decoration: none;
}

.forgot-password a:hover {
  text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .admin_login_main {
    flex-direction: column;
  }
}
