* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: Arial, sans-serif;
  }
  
  .help-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:white;
    padding: 20px;
    margin-top: 200px;
    margin-left:250px;
  }
  
  .contact-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    padding: 20px;
    max-width: 500px;
    width: 100%;
  }
  
  .contact-card h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form .form-row {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  .contact-form .form-row input {
    flex: 1;
  }
  
  .contact-form input, .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .contact-form textarea {
    resize: none;
    height: 100px;
  }
  
  .contact-form button {
    background-color:#5570F1;
    margin-left: 120px;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    width: 40%;
  }
  
  .contact-form button:hover {
    background-color: #001A3D;
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(255, 255, 255, 0.949);
    z-index: 1000;
    border-radius: 5px;
  }
  
  .popup-inner {
    text-align: center;
  }
  
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .contact-form .form-row {
      flex-direction: column;
    }
    .help-container {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color:white;
      padding: 20px;
      margin-top: 200px;
      margin-left:0;
    }
  }
  