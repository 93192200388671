/* Base styling for larger screens */
.staff-setting-container {
  padding: 40px;
  border-radius: 10px;
  margin: 70px auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  background-color: transparent;
  margin-left: 24rem;
}

.heading-settings {
  text-align: center;
  color: #333;
  font-size: 28px;
  margin-bottom: 30px;
  font-weight: 900;
}

h3 {
  color: #444;
  margin-bottom: 20px;
  font: 800;
}

.table-section {
  margin-bottom: 40px;
  margin-top: 20px;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
  text-align: left;
  border: 1px solid #ddd;
}

.styled-table th {
  background-color: #f2f2f2;
  font-size: 16px;
  color: #333;
}

.styled-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.styled-table tr:nth-child(odd) {
  background-color: #f1f1f1;
}

.input-field {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #333;
}

.input-field:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(149, 199, 230, 0.5);
}

.button-container {
  text-align: center;
}

.save-button {
  padding: 12px 30px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.save-button:hover {
  background-color: #87a9cc;
}

/* Responsive adjustments for screens up to 1400px */
@media (max-width: 1400px) {
  

  .heading-settings {
    font-size: 24px;
  }
}

/* Tablet view (1024px and below) */
@media (max-width: 1024px) {
 

  .heading-settings {
    font-size: 22px;
  }

  .styled-table th,
  .styled-table td {
    padding: 10px;
    font-size: 14px;
  }

  .input-field {
    font-size: 12px;
    padding: 6px;
  }

  .save-button {
    font-size: 14px;
    padding: 10px 20px;
  }
}

/* Mobile view (768px and below) */
@media (max-width: 768px) {
  .staff-setting-container {
    width: 100%;
    margin: 0 auto;
    padding: 15px;
  }

  .heading-settings {
    font-size: 20px;
  }

  .styled-table th,
  .styled-table td {
    padding: 8px;
    font-size: 12px;
  }

  .input-field {
    font-size: 12px;
    padding: 6px;
  }

  .save-button {
    font-size: 12px;
    padding: 8px 16px;
  }
}
