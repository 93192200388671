.main-branch-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    height: 100vh; /* Set main container height to full viewport height */
  }
  
  .top-container {
    padding: 5px; /* Adjust padding as needed */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .logo {
    width: 140px; /* Adjust max-width as needed */
    height: auto;
  }
  
  .horizontal-line {
    width: 100%;
    border: none;
    border-top: 1px solid black; /* Adjust line color and style as needed */
    margin: 5px 0; /* Adjust margin as needed */
  }
  
  .bottom-container {
    background-color: '#C5C3C3'; /* Set background color */
    width: 100%;
    height: 100%;
    padding: 20px; /* Adjust padding as needed */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  

  .content {
    text-align: center;
    padding: 20px; /* Adjust padding as needed */
    border-radius: 10px; /* Adjust border radius as needed */
  }
  
  .title {
    color: #091a44; /* Title color */
    text-decoration: underline;
  }
  
  .description {
    color: #091a44; /* Description color */
    margin-top: 10px; /* Adjust margin as needed */
  }
  .glassy-background {
    width: 80vw;
    height: auto;
    background-color: rgba(175, 175, 175, 0.5);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    margin-top: 20px;
    z-index: 1;
    filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.1));
    display: flex;
    flex-wrap: wrap; /* Allow children to wrap onto new lines */
    justify-content: center;
    align-items: center;
  }
  
  .card {
    flex: 1 0 calc(25% - 20px); /* Adjust card width based on the number of cards per row */
    max-width: calc(25% - 20px); /* Limit card width to ensure it doesn't grow too much */
    min-width: 200px; /* Set minimum width for the card */
    margin: 10px; /* Adjust margin as needed */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .add_card{
    flex: 1 0 calc(25% - 20px); /* Adjust card width based on the number of cards per row */
    max-width: calc(25% - 20px); /* Limit card width to ensure it doesn't grow too much */
    min-width: 200px; /* Set minimum width for the card */
    margin: 20px; /* Adjust margin as needed */
    box-sizing: border-box;
    display: flex;
    height: 250px;
    flex-direction: column;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }

  .card:hover,
.add_card:hover {
  transform: translateY(-5px) scale(1.05); /* Move up and scale up */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1); /* Add shadow */
}
  
    
  .initial-circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #ccc; /* Circle background color */
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 24px;
  }
  
  
  
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }  
  
  .branch-name {
    font-weight: bold;
    font-size: 18px; /* Adjust font size as needed */
  }
  
  .more-options {
    cursor: pointer;
  }

  .more-options {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transform: rotate(90deg); 
    margin-left: 10px;
    padding: 10px;
  }
  
  .dot {
    width: 2px;
    height: 2px;
    background-color: black;
    margin-left: 3px; /* Adjust spacing between dots */
    transform: rotate(90deg); /* Rotate the dots vertically */
  }
  
  .more-options {
    position: relative; /* Set position to relative */
  }
  
  .dropdown-menu {
    position: absolute; /* Set position to absolute */
    top: calc(100% + 5px); /* Position dropdown below more-options */
    right: 0; /* Align dropdown to the right */
    width: 40px; /* Set width of the dropdown */
    background-color: white; /* Background color */
    border: 1px solid #ccc; /* Border */
    border-radius: 5px; /* Border radius */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Box shadow */
  }
    
  .dropdown-item {
    padding: 8px 12px; /* Padding */
    cursor: pointer; /* Cursor */
    color: #091a44;
  }
  
  .dropdown-item:hover {
    background-color: #f1f1f1; /* Hover background color */
  }
  .horizontal-line {
    width: 100%;
    border: none;
    border-top: 1px solid #ccc; /* Adjust line color as needed */
    margin: 10px 0; /* Adjust margin as needed */
  }
    
  .buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px; /* Adjust margin as needed */
    
  }
  
  .admin-button,
  .staff-button {
    padding: 10px 20px;

    margin: 5px 10px;
    border: none;
    border-radius: 5px;
    background-color: #091a44; /* Button background color */
    color: #ffffff; /* Button text color */
    cursor: pointer;
  }

  .add-branch {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .plus-circle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #ccc; /* Circle background color */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .plus {
    font-size: 36px;
    color: black; /* Plus symbol color */
  }
  
  .bottom-container {
    position: relative;
  }
  
  .quarter-circle {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 150px; /* Adjust the size of the quarter circle */
    height: 150px; /* Adjust the size of the quarter circle */
    background-color: #BE8C39; /* Color of the quarter circle */
    border-radius: 0 0 0 100px; /* Adjust the border radius to create a quarter circle */
    transform: rotate(90deg); /* Rotate the quarter circle */
  }


  .b-button-cont{
    padding: 10px;
  }

  .b-button-cont :hover{
    background-color: #091a44;
    color: white;
    transform: scale(1.1);
    z-index: 1;
  }
  
    @media (max-width: 768px) {
        .quarter-circle {
        display: none;
        }
    }