.gb_dash_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100% !important;
  padding-top: 20px;
  margin: 0 0 0 10rem;
  overflow-x: hidden;
}
 /* Base container styles */


.invoice-section,
.user-info {
  width: 100%; /* Set to full width within the container */
  max-width: 100%; /* Ensure max-width covers the container */
}

/* Responsive settings */
@media (min-width: 1200px) {
  .invoice-section,
.user-info {
    width: 75rem;

  }
}

@media (max-width: 1024px) {
  .gb_dash_main,
  .invoice-section,
  .user-info {
    width: auto; /* Adjust width for smaller screens */
  }
}


.gb_horizontal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto !important;
  padding: 20px;
  margin-top: 3rem;
}


.gb_h1, .gb_h2 {
  width: auto;
  text-align: center; /* Center-align headings for a cleaner look */
}

.gb_ver_nav {
  width: auto;
}

/* User Info Container */
.user-info {
  display: flex;
  flex-direction: column;
  gap: 20px;  /* Increased gap for better spacing */
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  display: block;
}

.user-info .user-stats {
  display: flex;
  justify-content: space-between;  /* Distribute items evenly across the container */
  gap: 2.5rem;  /* Ensure equal spacing between the stat items */
}

.user-info .user-stats .edit-button {
  background: none;        /* Removes the background color */
  border: none;            /* Removes the border */
  color: blue;             /* Sets the text color to blue */
  text-decoration: underline;  /* Optionally adds an underline for clarity */
  font-size: 16px;         /* Adjust font size as needed */
  cursor: pointer;        /* Makes the button clickable */
  padding: 0;              /* Optional, to adjust button padding */
  margin-top: 10px;        /* Optional, space above the button */
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;      /* Center text for better alignment */
}

.stat-item span {
  font-weight: bold;
  font-size: 18px; 
  color: #888888;
  /* Slightly bigger for better readability */
}

.stat-item h2 {
  font-size: 18px;
    font-weight: 500;
    margin: 16px 0 ;
    color: #333;

  }

.stat-item small {
  font-size: 14px;         /* Slightly bigger small text */
  color: #007bff;
}


/* Invoice Section */
.invoice-section {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px; /* Added more padding for better layout */
  margin-bottom: 20px;
  /* width: 80%;
  margin: 0 auto; */
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Increased gap for better spacing */
}
#form-titles{
  color: #333;
  text-align: left;
  font: 800;
}

.form-section {
  display: flex;
  flex-direction: column;
}

.form-row {
  display: flex;
  gap: 6rem;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 1rem;
}

.form-groups {
  flex: 1;
  min-width: 220px; /* Adjusted min-width */
  max-width: 300px; /* Added max-width for consistent sizing */
  display: flex;
  flex-direction: column;
  font-weight: bold;
  margin-top: 1rem;

}
.type-time{
margin-top: 3rem;
}
.forms-groups
{
  flex: 1;
  min-width: 220px; /* Adjusted min-width */
  max-width: 300px; /* Added max-width for consistent sizing */
  display: flex;
  flex-direction: column;
  font-weight: bold; 
}

.form-groups label {
  font-size: 17px;
  margin-bottom: 30px;
  text-align: left;
}
.forms-groups label {
  font-size: 17px;
  margin-bottom: 30px;
  text-align: left;
}

/* Make the selector more specific */
.form-container .form-groups input, 
.form-container .form-groups select {
  padding: 10px; /* Increased padding for a cleaner input feel */
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  width: auto;
  color: #CCCCCF;

}
.form-container .forms-groups input, 
.form-container .forms-groups select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  width: auto;
  border: none;
}


.generate-button-invoice {
  width: auto;
  padding: 14px; /* Increased padding for a better look */
  background-color: #3a6eff;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 0;

}

.generate-button-invoice:hover {
  background-color: #2f55cc;
}
.button-row{
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Responsive settings */
@media (max-width: 1024px) {
  .user-info, .invoice-section {
    width: 48rem; /* Adjust container width */
  }
  .user-info .user-stats {
     /* Distribute items evenly across the container */
    gap: 1.5rem;  /* Ensure equal spacing between the stat items */
  }

  .form-row {
    gap: 15px; /* Adjust gap */
  }

  .form-groups {
    min-width: 200px; /* Adjust min-width */
  }
  .forms-groups {
    min-width: 200px; /* Adjust min-width */
  }
  /* .gb_dash_main {
    margin: 0 0 0 13rem
  } */
}


@media (max-width: 768px) {
  .user-details h3 {
    font-size: 14px;
  }

  .form-groups {
    min-width: 180px; /* Further reduce min-width */
  }
  .forms-groups {
    min-width: 180px; /* Further reduce min-width */
  }

  .generate-button-invoice {
    font-size: 12px;
  }
  .gb_dash_main {
   width: min-content;
   margin: 0;
  }
}
@media screen and (max-width: 768px) {
  .user-info {
    display: none;
  }
  .user-info, .invoice-section {
    width: 100%; /* Adjust container width */
  }
}
 
/* Product Table Styles */
.services-table {
  width: 100%;
  margin-top: 20px;
}
.gb_services-table-content
{
  width: 100%;
  text-align: left;
}
.gb_service-table-field{
  width: auto;
}
.services-table-content {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.services-table-content th,
.services-table-content td {
  padding: 12px 15px;
  text-align: left;
  border: 1px solid #ddd;
}

.services-table-content th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.service-table-field {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.service_dp {
  width: 100%;
  padding: 0;
}

.status-dropdowns {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}
/* Points Input Section */
.gbform-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

#date_input_field {
  width: 100%;
  padding: 10px;
  border: 1px solid #CFD3D4;
  border-radius: 8px;
  margin: 5px 0 10px;
  transition: border-color 0.3s ease;
  font-size: 1rem;
}

#gb_input-field {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
}
/* GST Input Section */
.gbform-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}


.gbform-group label {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
  text-align: left;
}
.gb_membership-select{
  padding: 0.6rem;
    align-items: end;
    display: flex;
    /* gap: 21rem; */
    margin-top: 1rem;
    width: 20%;
}
.gb_membership-label{
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  color: black;
}
#points{
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  width: fit-content;
}
#memebership_points
{
  margin-top: 1rem;
  width: 30%;
}
.gb_services-table-content {
  width: 100%;
  border-collapse: collapse; /* Ensures borders are collapsed between cells */
  margin: 20px 0; /* Adds space above and below the table */
}

.gb_services-table-content th, .gb_services-table-content td {
  padding: 12px 20px; /* Adds padding for better spacing inside cells */
  text-align: left; /* Aligns text to the left */
  border: 1px solid #ddd; /* Adds a light border around each cell */
}

.gb_services-table-content th {
  background-color: #f4f4f4; /* Light background for header */
  font-weight: bold; /* Makes header text bold */
}

.gb_services-table-content tr:nth-child(even) {
  background-color: #f9f9f9; /* Alternates row colors for readability */
}

.gb_services-table-content tr:hover {
  background-color: #f1f1f1; /* Highlights rows when hovered */
}

/* Popup Styling */
.popups {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the popup is on top of other content */
}

.popups-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 900px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow-y: auto; /* Allow scrolling inside the popup */
  max-height: 80vh; /* Limit the height of the popup */
}



/* Table Styles */
.table-container {
  overflow-x: auto;
  margin-top: 20px;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.responsive-table th,
.responsive-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.responsive-table th {
  background-color: #3f51b5;
  color: white;
  font-size: 16px;
}

.responsive-table td {
  background-color: #fff;
}

.responsive-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.responsive-table td div {
  padding: 5px;
  border: 1px solid #ddd;
  margin: 2px 0;
}

/* Ensure Content Behind the Popup Is Accessible */
body {
  overflow: auto;
  height: 100%;
}

/* Responsive Design */
@media (max-width: 768px) {
  .popups-content {
    width: 90%;
    padding: 15px;
  }

  .responsive-table th, .responsive-table td {
    font-size: 14px;
    padding: 8px;
  }

  #close-btns {
    padding: 4px 8px;
    font-size: 14px;
  }
 

  #cd_flex{
    flex-wrap: wrap;
  }
}

@media (max-width: 480px) {
  .responsive-table th, .responsive-table td {
    font-size: 12px;
    padding: 6px;
  }

  .popups-content {
    width: 95%;
    padding: 10px;
  }  

  #service-tabel{
    overflow: auto;
  }
  #product-tabel{
    overflow: auto;
  }
}