* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', sans-serif;
}

.personal_information_container {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;

}

.pi_main {
  padding: 10px;
  flex: 1;
}

.pi_horizontal_container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.pi_horizontal_item {
  max-width: 600px;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.pi_horizontal_item img {
  width: 100%;
  max-width: 300px;
}

.pi_horizontal_item h2 {
  margin-bottom: 10px;
  color: #091a44;
  margin-left: 70px;
}

.pi_input_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Set to 100% for consistency */
  max-width: 400px; /* Limit max-width for input container */
  margin-top: 10px;
  margin-left: 10px;
}

.pi_input_container label {
  font-weight: bold;
  text-align: center;
}

.pi_input_container input {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%; /* Set width to 100% */
  padding: 10px; /* Adjust padding for usability */
  font-size: 14px;
  max-width: 380px; /* Set a max width to ensure consistency */
}

.pi_input_container input:focus {
  outline: none;
  border-color: #007bff;
}

.pi_up_container {
  margin-top: 5px;
  display: flex;
  justify-content: center;
}

.pi_update_button {
  font-family: 'Montserrat', sans-serif;
  background-color: transparent;
  color: black;
  border: 2px solid #5570F1;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  width: 200px;
  transition: background-color 0.3s, color 0.3s;
  margin-right: 60px;
}

.pi_update_button:hover {
  background-color: #5570F1;
  color: white;
}

.pi_update_button:focus {
  outline: none;
}

/* Responsive adjustments */
@media screen and (min-width: 768px) {

  
  .pi_horizontal_item img {
    max-width: 400px;
    margin-left: 150px;
  }
}
