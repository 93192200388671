/* Reset and Base Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', sans-serif;
}

html, body {
  height: 100%;
}

.invoice_comment {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

/* Invoice Container and Main Styles */
.invoice_container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  flex-direction: column;
  padding: 20px;
}

.invoice_main {
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 80vw;
  padding: 20px;
  border-radius: 8px;
}

/* Invoice Header Styles */
.invoice_header {
  border: 1px solid #ddd;
  padding: 20px;
  display: flex;
  justify-content: space-between; /* Align elements to edges */
  align-items: center;
  margin-bottom: 20px;
}

.invoice_logo {
  width: 15%;
  height: auto;
}

.invoice_name {
  font-size: 1.8rem; /* Increased font size */
  font-weight: bold;
  padding-left: 450px;
}

/* Invoice Content Styles */
.invoice_content {
  display: flex;
  justify-content: space-between; /* Space between left and right */
  padding: 20px 0;
}

.invoice_left, .invoice_right {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 45%; /* Increased width */
}

.invoice_right {
  align-items: flex-end;
}

/* Invoice Table Styles */
.table-responsive {
  width: 100%;
  overflow-x: auto;
  margin-top: 20px;
}

.invoice_table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.invoice_table td,
.invoice_table th {
  padding: 12px; /* Increased padding */
  background: #f9f9f9;
  border-bottom: 1px solid #ddd;
  text-align: center;
}

.invoice_table th {
  font-size: 16px; /* Increased font size */
  background-color:white ;
  color:black ;
}

.invoice_table td h3 {
  font-size: 1.1em; /* Increased font size */
}

.invoice_table .qty,
.invoice_table .total,
.invoice_table .unit {
  text-align: right;
}

/* Invoice Footer Styles */
.invoice_footer {
  display: flex;
  justify-content: space-between; /* Space between footer items */
  padding: 20px;
  border-top: 1px solid #ddd;
}

.invoice_footer_left, .invoice_footer_right {
  display: flex;
  flex-direction: column;
  width: 48%; /* Adjust width for footer */
}

.invoice_footer_left {
  align-items: flex-start;
}

.invoice_footer_right {
  align-items: flex-end;
}

/* Editable Field Styles */
.editable-field {
  border: none;
  outline: none;
  padding: 5px;
  text-align: center;
  background-color: transparent;
}

/* Generate Button Styles */
.download_button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.download_button button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px; /* Increased font size */
  font-weight: bold;
  transition: background-color 0.3s;
  margin-right: 40px;
}

.download_button button:hover {
  background-color: #0056b3;
}

/* Hide Generate Button on Print */
@media print {
  .download_button {
    display: none;
  }
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .invoice_main {
    width: 90vw;
  }
}

@media (max-width: 768px) {
  .invoice_main {
    width: 100vw;
  }

  .invoice_left, .invoice_right {
    width: 100%; /* Full width on smaller screens */
  }

  .invoice_content {
    flex-direction: column; /* Stack content */
    align-items: flex-start; /* Align to the left */
  }
}

@media (max-width: 576px) {
  .invoice_logo {
    width: 30%; /* Adjust logo size */
  }

  .download_button button {
    width: 80%;
  }

  .invoice_footer {
    flex-direction: column; /* Stack footer items */
    align-items: flex-start; /* Align to the left */
  }
}
.generate-button-container{
  display: flex;
  justify-content: center;
  align-items: center;
}