/* Container for the entire customer loyalty component */
.cl_container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: white;
    margin-top: 2rem;
    margin-right: 50px;
    margin-left: 350px;
}

/* Main area for the customer list */
.cl_main {
    display: flex;
}

/* Header styling */
.cl_headerContainer {
    display: flex;
    flex-direction: column;
}

/* Header actions styling */
.cl_headerActions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

/* Search container to align the icon and input */
.cl_searchContainer {
    position: relative;
}

/* Search icon styling */
.cl_searchIcon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #aaa;
}

/* Search bar input styling */
.cl_searchBar {
    padding: 10px 10px 10px 40px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 20px;
    width: 100%;
}

/* Button styling for adding customers */
.cl_add {
    display: flex;
    align-items: center;
    background-color: #5570F1;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
}

/* Hover effect for button */
.cl_add:hover {
    background-color: #4560d1;
}

/* Table wrapper to allow scrolling */
.cl_table_wrapper {
    overflow-x: auto;
}

/* Table styling */
.cl_table {
    width: 100%;
    border-collapse: separate; /* Prevent borders from collapsing */
    margin-top: 20px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    margin-right: auto; /* Center the table */
    border: 1px solid #e0e0e0; /* Light border for the table */
}

.updates{
    width: 100%;
}

/* Table header and cell styling */
.cl_table th,
.cl_table td {
    padding: 10px;
    text-align: center;
    border: none; /* Remove cell borders */
}

/* Table header styling */
.cl_table th {
    background-color: white;
    color: #B5B7C0;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: -0.01em;
}

/* Table cell styling */
.cl_table td {
    background-color: white;
    color: #292D32;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: -0.01em;
}

/* Divider styling */
.cl_divider {
    margin: 20px 0;
    border: none;
    height: 1px;
    background-color: #ddd;
}
 @media (max-width: 768px) {
    
        .cl_container {
            display: flex;
            flex-direction: column;
            height: 100vh;
            background-color: white;
            margin-top: 2rem;
            margin-right: 50px;
            margin-left: 50px;
        }
}

