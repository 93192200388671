.global-error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  
  .global-error-content {
    text-align: center;
    max-width: 600px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .global-error-heading {
    font-size: 36px;
    color: #e74c3c;
    margin-bottom: 20px;
  }
  
  .global-error-message {
    font-size: 18px;
    color: #333;
  }
  
  @media only screen and (max-width: 768px) {
    .global-error-heading {
      font-size: 28px;
    }
  
    .global-error-message {
      font-size: 16px;
    }
  }
  