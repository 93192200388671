/* Modal Overlay */
.modal1-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modal1-content {
  background: #ffffff;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 80vh; /* Limit the height to make space for scrolling */
  overflow: hidden; /* Hide overflow to contain the scrollable area */
}

/* Scrollable Form Container */
.scrollable1-form {
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: calc(80vh - 100px); /* Adjust to fit within the modal content */
  padding-right: 10px; /* Padding to avoid overlap with scrollbar */
  margin-bottom: 20px; /* Space for button group */
}

/* Heading */
.modal1-content h2 {
  margin-bottom: 15px;
  font-size: 1.8rem;
  color: #333;
  text-align: center;
  font-weight: 600;
}

/* Form Group */
#form1-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

#form1-group label {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 5px;
  font-weight: 500;
}

#form1-group input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 0.95rem;
  transition: border-color 0.3s ease;
}

#form1-group input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}

/* Button Group */
#buttons-group {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: auto; /* Push buttons to the bottom */
  position: sticky;
  bottom: 0;
  background: #ffffff;
  padding: 10px 0;
}

#buttons-group buttons {
  flex: 1;
  padding: 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.95rem;
  transition: background-color 0.3s, transform 0.1s;
  font-weight: 600;
}

#buttons-group buttons:first-child {
  background-color: #28a745;
  color: #fff;
  align-items: center;
  display: flex;
  justify-content: center;
}

#buttons-group buttons:first-child:hover {
  background-color: #218838;
}

#buttons-group buttons:last-child {
  background-color: #dc3545;
  color: #fff;
  align-items: center;
  display: flex;
  justify-content: center;
}

#buttons-group buttons:last-child:hover {
  background-color: #c82333;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Responsive Adjustments */
@media (max-width: 480px) {
  .modal1-content {
    padding: 15px;
    max-width: 90%;
  }

  #buttons-group button {
    padding: 10px;
    font-size: 0.85rem;
  }

  #form1-group input {
    font-size: 0.9rem;
  }
}